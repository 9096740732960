.SquareIconData{
    width: 75px;
    height: 80px;
    margin-right: 30px;

    &-icon-container{
        width: 50px;
        height: 50px;
        background-color: #F3F7F9;
        border-radius: 50%;
        margin-left: 13px;
    }

    i {
        font-size: 25px !important;
        color: #9BA3AC !important;
        display: block;
    }

    label {
        line-height: 16px;
        font-size: 12px;
        font-weight: 800;
        display: block;
        margin-top: 10px;
    }

}