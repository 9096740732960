.text {
  &.green {
    color: #41af4b;
  }

  &.azure {
    color: #00aaff;
  }

  &.yellow {
    color: #FFBB00;
  }

  &.yellow-light {
   color: #e6a900
  }

  &.light-gray {
    color: #747474;
  }

  &.white {
    color: #ffffff;
  }
  &.red {
    color: #f7274e;
  }
}

.bg {
  &.green {
    background-color: #41af4b;
  }

  &.azure {
    background-color: #00aaff;
  }

  &.yellow {
    background-color: #FFBB00;
  }

  &.light-gray {
    background-color: #747474;
  }

  &.white {
    background-color: #ffffff;
  }
  &.red {
    background-color: #f7274e;
  }
}

.border {
  &.green {
    border-color: #41af4b;
  }

  &.azure {
    border-color: #00aaff;
  }

  &.yellow {
    border-color: #FFBB00;
  }

  &.light-gray {
    border-color: #747474;
  }

  &.white {
    border-color: #ffffff;
  }

  &.red {
    border-color: #f7274e;
  }

}

/*Temporal comment*/