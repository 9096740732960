.alojamiento {

    &-linea{
        margin: 0px;
        color: #F4F4F4;
    }

    &-descripcion 
    {
        padding-left: 38px;
        margin-right: 30px;

        label{
            display: block;        
        }
    }

    &-duenio-descripcion {
        padding-left: 30px;

        label{
            display: block;
        }
    }

    &-count-result{
        text-align: center;
        padding-top: 10px;
    }

    &-icon{
        margin-left: 29px;
        padding-top: 22px;
        color: #a9a9a9;
    }
}

#btnAprobarHuesped, #btnRechazarHuesped, #btnCancelarReservacion,
#inputTelefono, #inputDireccion, #textMensajeHuesped{
    width: 100%;
}
