.container {
  max-width: 100%;
}

.table-report {
  margin: 2em 0;
}

.tabs {
  align-items: stretch;
  display: flex;
  font-size: 1rem;
  justify-content: space-around;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 1em;

  ul {
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start;

    li {
      flex-grow: 1;
      flex-shrink: 0;
      border-top: 1px solid #c1c3c7;
      border-right: 1px solid #c1c3c7;
      border-bottom: 1px solid #c1c3c7;
      cursor: pointer;

      &:first-child {
        border-left: 1px solid #c1c3c7;
      }

      &.active {
        background-color: #ffffff;
        position: relative;
        border-top: 1px solid transparent;

        &:before {
          display: block;
          content: '';
          background: #00aaff;
          width: 100%;
          position: absolute;
          top: -1px;
          height: 0.2em;
        }
      }
    }
  }

  a {
    align-items: center;
    color: #747474;
    display: flex;
    justify-content: center;
    margin-bottom: -1px;
    padding: 1.5em 1em;
    vertical-align: top;
    font-size: 18px;
    text-decoration: none;

    span {
      margin-left: 0.2em;
      font-weight: bold;
    }
  }
}

/****** HEADER TABLE ******/

.table {
  margin-top: 2em;
  width: 100%;
  border-color: #fff;
  border-spacing: 0;

  &.no-pad {
    margin-top: 0;
  }

  thead {
    th {
      border-bottom: 1px solid #c1c3c7;
      border-top: 1px solid #c1c3c7;

      &:first-child {
        border-left: 1px solid #c1c3c7;
      }
      &:last-child {
        border-right: 1px solid #c1c3c7;
      }
    }
    a {
      display: flex;
      padding: 1em 0;
      color: #747474;
      font-size: 14px;
      font-weight: normal;
      margin-left: 1em;
      text-decoration: none;

      &.actions {
        text-indent: -9999px;
        text-align: center;
      }

      i {
        color: #00aaff;
        margin-left: 0.5em;
      }
    }
  }

  tbody {
    background-color: #ffffff;
    tr.azure.border-left {
      td:first-child {
        border-left: 2px solid #00aaff;
      }
    }
    tr.yellow.border-left {
      td:first-child {
        border-left: 2px solid #ffbb00;
      }
    }
    tr.yellow-light.border-left {
      td:first-child {
        border-left: 2px solid #747474;
      }
    }
    tr.border-left {
      td:first-child {
        border-left: 3px solid #00aaff;
      }
    }
    td {
      padding: 0.7em;
      border-bottom: 1px solid #c1c3c7;

      .text.item {
        color: #747474;
        font-weight: bold;
        font-size: 15px;
      }

      button {
        width: initial;
        padding: 0.5em;
        text-transform: none;
        font-size: 14px;
        font-weight: normal;
      }
      &:last-child {
        border-right: 1px solid #c1c3c7;
      }
    }
  }
}

.pagination {
  position: relative;
  display: flex;
  padding: 1em;
  justify-content: space-around;

  ul {
    /*display: flex; */
    li {
      padding: 0 1em;
      float: left;
      margin-top: 5px;
    }
  }

  a {
    position: relative;
    text-decoration: none;
    color: #747474;
    i {
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
}

.icon-orden {
  width: 15px;
  margin-left: 8px;
}

.icon-eliminar {
  width: 15px;
  margin-left: 15px;
  cursor: pointer;
}
.icon-editar {
  width: 15px;
}

.actions {
  text-align: center;
}

.btnView {
  cursor: pointer;
}


.pagination-controls__button--active, a {
  //color: #0088CC !important;

}

.pagination-controls__button--active {
  background-color: #33C1FF !important;
  border-radius: 10px;
  color: #0088CC !important;

}

.pagination-controls__button_number--active {
  background-color: white !important;
  border-radius: 10px;
  color: #0088CC !important;

}


.ckTable {
  display: block !important;
  width: auto !important;
}
.ckTableTh {
  display: block !important;
}

.slAll {
  padding: 2em 0;
  color: #747474;
  font-size: 14px;
  font-weight: normal;
}

.title {
  color: #00aaff;
}

