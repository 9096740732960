@font-face {
  font-family: 'icomoon';
  src: url('https://siigostatics.azureedge.net/leaf/@v1.0.0/fonts/icomoon.eot?o54a9m');
  src: url('https://siigostatics.azureedge.net/leaf/@v1.0.0/fonts/icomoon.eot?o54a9m#iefix')
      format('embedded-opentype'),
    url('https://siigostatics.azureedge.net/leaf/@v1.0.0/fonts/icomoon.ttf?o54a9m')
      format('truetype'),
    url('https://siigostatics.azureedge.net/leaf/@v1.0.0/fonts/icomoon.woff?o54a9m')
      format('woff'),
    url('https://siigostatics.azureedge.net/leaf/@v1.0.0/fonts/icomoon.svg?o54a9m#icomoon')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.siicon {
  font-family: 'icomoon' !important;

  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.icon-charts::before {
    content: '\e90b';
    display: block;
  }

  &.icon-check::before {
    content: '\e900';
    display: block;
  }

  &.icon-cogs::before {
    content: '\e902';
    display: block;
  }

  &.icon-copy::before {
    content: '\e903';
    display: block;
  }
  &.icon-logo::before {
    content: '\e904';
    display: block;
  }
  &.icon-question::before {
    content: '\e9df';
    display: inline;
    color: #00AAFF;
  }
  &.icon-notes::before {
    content: '\e905';
    display: block;
  }
  &.icon-rectangle::before {
    content: '\e906';
    display: block;
  }
  &.icon-search::before {
    content: '\e980';
    display: block;
    color: #666464;
  }
  &.icon-search:hover:before {
    content: '\e980';
    display: block;
    color: #363434;
    font-weight: bold;
    cursor: pointer;
  }
  &.icon-excel::before {
    content: '\eb76';
    display: block;
    color: #7ac23a;
    
  }
  &.icon-excel-white::before {
    content: '\eb76';
    display: block;
    color: #ffff;
  }
  &.icon-excel:hover::before {
    content: '\eb76';
    display: block;
    cursor: pointer;
    color: #69aa29;
  }
  &.icon-sort-down::before {
    content: '\e908';
    display: block;
  }
  &.icon-items::before {
    content: '\e901';
    display: block;
  }
  &.icon-trash::before {
    content: '\e909';
    display: block;
  }

  &.icon-deleteX::before {
    content: '\ea21';
    display: block;
    color:red
  }
  
  &.icon-user::before {
    content: '\e925';
    display: block;
  }
  &.icon-indicadores::before {
    content: '\e91d';
    display: block;
  }
  &.icon-service::before {
    content: '\ecc5';
    display: block;
  }
  &.icon-market::before {
    content: '\e951';
    display: block;
  }
  &.icon-microsoft::before {
    content: '\ebdc';
    color: #9c9c9c;
  }
  &.icon-atras::before {
    content: '\ebbd';
    color: #00aaff;
  }
  &.icon-ver::before {
    content: '\ec00';
    color: #00aaff;
  }
  &.icon-cancel::before {
    content: '\e908';
    display: block;
    color: #f7274e;
  }
  &.icon-cancel:hover:before {
    content: '\e908';
    display: block;
    color: #da1438;
    font-weight: bold;
    cursor: pointer;
  }
  &.icon-reset::before {
    content: '\e908';
    display: block;
    font-weight: 500 !important;
    color: #666464;
  }
  &.icon-reset:hover:before {
    content: '\e908';
    display: block;
    color: #363434;
    font-weight: 50 !important;
    //font-weight: bold;
    cursor: pointer;
  }
  &.icon-close::before {
    content: '\ec27';
    display: block;
    color: #f7274e;
  }

  &.icon-reload::before {
    content: '\ebc8';
    display: block;
    cursor: pointer;
    color: #ffa532;
  }

  &.icon-reload:hover::before {
    content: '\ebc8';
    display: block;
    cursor: pointer;
    color: #f38d09;
  }

  &.icon-leads::before {
    content: '\e990';
    display: block;
  }
  &.icon-detalles::before {
    content: '\e9ac';
    color: #9c9c9c;
  }
  &.icon-synergy::before {
    content: '\e95b';
    display: block;
  }
  &.icon-send::before {
    content: '\ebb9';
    margin-right: 5px;
  }
  &.icon-aurora::before {
    content: '\e901';
    display: block;
  }
  &.icon-contratos::before {
    content: '\eb95';
    display: block;
  }

  &.icon-copiar::before {
    content: '\eb72';
    color: #000;
    font-size: 20px;
  }

  &.icon-siigo-app-correo::before {
    content: '\e90c';
    font-size: 20px;
    color: #0af;
  }

  &.icon-siigo-app-editar::before {
    content: '\e911';
    font-size: 20px;
    color: #0af;
  }

  &.icon-siigo-construccion-cono::before {
    content: '\e9ca';
    font-size: 20px;
    color: white;
  }
  
  &.icon-folder::before {
    content: '\eb91';
    font-size: 20px;
    color: white;
  }
  &.icon-arrow-rigth::before {
    content: '\276F';
    font-size: 12px;
    color: white;
  }
}
