.title-w2c {
  height: 38px;
  width: 159px;
  color: #37465a;
  font-family: 'Muli', sans-serif;
  font-size: 26px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 38px;
}

.subtitle {
  height: 24px;
  width: 400px;
  color: #37465a;
  //font-family: "Nunito Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.container-button-add {
  float: right;
}
.notification-box {
  position: fixed;
  bottom: 3%;
  right: 34%;
}
