.accessPermissionContainer{

    display: flex;
    justify-content: start;
    align-items: center;

    input {
        display: block !important;
        width: 20px;
    }

    label {
        display: block !important;
        color: #747474;
        font-size: 1em;
    }
}