.slFilter {
  border-bottom: 1px solid #747474;
}

.inputFilter {
  padding-left: 0;
  // border-bottom: 3px solid #747474!important;
}

.btnBuscar {
  margin-right: 5px;
}

.divTable {
  //overflow-x: auto;
  width: 100%;
}
.rowModal {
  margin-bottom: 5px;
}

.h3Modal {
  margin-top: 5px;
}

.sep {
  border: none !important;
  height: 1px !important;
  /* Set the hr color */
  color: #0af; /* old IE */
  background-color: #0af; /* Modern Browsers */
}

.errorState {
    color: red !important;
    font-weight: bold;
    font-style: italic;
}

.btnDownload {
    margin: 0 0 2em 0;
}

.table-components{
    overflow-x: scroll;
}


.warningState{
  color: darkorange !important;
  font-weight: bold;
  font-style: italic;
}

.successState{
  color: green !important;
  font-weight: bold;
  font-style: italic;
}

.inputCheck {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
  justify-content:flex-start;
  align-items:center;
  label {    
    color: #747474;
    font-size: 16px;
  }
  input{
    padding-left: 0px;
    width: 20%;
  }
}


.input-container-accountant {
  margin-bottom: 30px;

  width: 100%;

  input {
    padding-left: 0px;
    padding-bottom: 0px;
    display: block;
    color: #747474;
    margin-top: 10px;
  }
  label {    
    color: #747474;
    font-size: 13px;
  }

  select{
    background-color: white;
    margin-top: 3px;
  }

  textarea {
    width: 90%;
    color: #747474;
    outline: none !important;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
  }
}

.inputFocus {
  border-bottom: 1px solid #00aaff;
}

.customEnterText {
  display: inherit !important;
  border-bottom: 1px solid #747474 !important
}

.labelModal {
  display: block !important;
  color: #00aaff;
}

.inputFocus {
  border-bottom: 1px solid #00aaff;
}

.inputError {
  border-bottom: 1px solid #f7274e;
}

.labelError {
  display: block !important;
  color: #f7274e;
}
.inputOk {
  border-bottom: 1px solid #7ac23a;
}

.labelOk {
  display: block !important;
  color: #7ac23a;
}

.uxDetailContainer
{
  margin: 8px 0px;
}

.divTableModal{
  .container{
    margin: 0;
  }

  .container .table-components table{
    margin-top: 0;
  }


}

.massiveActionsContainer{

  margin-left: 20px;
  text-align: center;

  label{
    display: inline-block;
    color: #747474;
  }

  input[type="checkbox"]{  
    display: inline-block !important;
    width: 20px;
  }
}

.chResendEmail{
    display: inline-block !important;
    width: 20px;  
}

.divFilter{
  display: flex !important;
  align-items: center;
}

.massiveActions{
  margin-left: 20px;
  display: flex;
  align-items: center;

  .input-container-accountant{
    margin-right: 15px;
  }

  .submitMassiveActions{
    width: 140px;
    height: 35px;
  }
}


.InputFromTo{
  display: flex;
  margin-right: 10px;
}


.table thead a.actions{
  text-indent: 1px !important;
  margin-right: 15px;
}