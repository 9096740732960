.siicon-process {
  font-family: 'icomoon' !important;

  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.icon-excel::before {
    content: '\eb76';
    margin: 0em 0.5em 0em;
    color: #7ac23a;
    font-size: 2em;
  }

  &.icon-excel:hover::before {
    content: '\eb76';
    cursor: pointer;
    color: #69aa29;
  }

  &.icon-load::before {
    content: '\ec3d';
    margin: 0em 0.5em 0em;
    color: #7ac23a;
    font-size: 2em;
  }

  &.icon-load:hover::before {
    content: '\ec3d';
    color: #69aa29;
  }

  &.icon-deleteX::before {
    content: '\ea21';
    font-size: 1.5em;
    color: red;
    cursor: pointer;
  }
}

.buttons {
  background: #00aaff;
  color: #ffffff;
  //text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  box-shadow: none;
  padding: 5px 0;
  border-radius: 25px;
  font-family: 'Muli', sans-serif;
  //width: 100%;
  padding: 5px 20px;
  border: 1px solid transparent;
  cursor: pointer;
  float: right;
  margin-top: -2.7em;

  &.green-process {
    color: #7ac23a;
    background: transparent;
    border: 1px solid #7ac23a;
    padding: 5px 20px;
    border-radius: 14px;
    align-self: center;

    &:hover {
      background-color: #7ac23a;
      border-color: transparent;
      color: #ffffff;
    }
  }
}

.description-green {
  vertical-align: 8px;
  color: #7ac23a;
}

.marginLeft {
  margin-left: 10px;
}

.column {
  padding: 20px;
}

.column-description {
  padding-top: 0.5em;
}

.rownd {
  display: flex;
}

.rows {
  display: flex;
}

.divInformation {
  padding: 15px;
  margin-top: 25px;
  background-color: #fff;
  border-radius: 10px;
}

.input-process {
  opacity: 12;
  width: 10.3em;
  height: 2em;
  display: inline-block;
  vertical-align: 8px;
  color: #7ac23a;
  cursor: pointer;
  padding: 0px;
}

#loadExcel::before {
  content: "Subir formato de excel";
  text-decoration: underline;
}

.title {
  color: #7ac23a;
  font-size: 18px;
}

.bulk-load {
  font-size: 24px;
  color: #00aaff;
}

.load-excel {
  color: #747474;
  font-weight: bold;
  font-size: 17px;
  padding-left: 0.5em;
  vertical-align: 6px;
}

.hModal {
  display: block;
  margin-bottom: 15px !important;
  margin-top: -10px;
  text-align: center;
  color: #747474;
  font-weight: bold;
}

.input-containers {
  margin-bottom: 30px;

  width: 100%;
}

.downloadExcelContainer span {
  cursor: pointer;
  text-decoration: underline;
}

.grid-user-edit {
  display: grid;
  grid-template-columns: 1fr;
  padding: 15px;
  margin-top: 25px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

.link-password {
  font-size: 12px;
}

.ckeckcontainer {
  position: relative;

  .labelcheckadmin {
    position: absolute;
    margin-left: 20px;
    margin-top: -4px;
  }
}

.flex-init {
  display: flex;

  .sub-title {
    flex: 1;
  }
}

h3 {
  a {
    color: #00aaff;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

input,
select {
  font-family: "Muli", sans-serif;
  font-size: 16px;
  padding-left: 30px;
  padding-bottom: 7px;
  border-color: currentcolor currentcolor #737373;
  border-left: 1px #737373;
  border-width: 1px !important;
  border-right: 1px #737373;
  border-top: 1px #737373;
  width: 90%;
}

.a-button {
  background: #0af;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  box-shadow: none;
  padding: 5px 0;
  border-radius: 25px;
  font-family: "Muli", sans-serif;
  border: 1px solid transparent;
  cursor: pointer;
  text-decoration: none;
  float: right;
  margin-top: -10px;
}

.a-button.red-button {
  border: 1px solid #f70e0e !important;
  background: transparent;
  padding: 9px 22px !important;
  align-self: center;
  color: #f70e0e !important;
  font-size: 13px;
}

.error-message {
  font-size: 13px;
  color: #f7274e;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mtop-select {
  margin-top: -7px;
}

.companyDetail-documents {
  border: 1px solid #ccc;
  min-height: 100px;
  background-color: #fff;
  margin: 40px 0px;
  padding: 30px 80px;

  .title-documents {
    color: #4b596b;
    margin: 0px;
    font-size: 17px;
    font-weight: bold;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    p {
      font-size: 15px;
      color: #00aaff;
    }

    .num-documents {
      width: 50%;
      margin-top: 20px;
    }

    .documents-aditionals {
      width: 50%;
      margin-top: 20px;

      .title {
        display: flex;
      }

      .tooltip {
        .tooltiptext {
          bottom: -400% !important;

          &::after {
            top: -26% !important;
            left: 11.5% !important;
          }
        }
      }

      a {
        color: #3fabff;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .row {
      display: flex;
      justify-content: start;
      margin: 12px 0px;
      gap: 30px;
    }
  }
}

.header-company-detail {
  display: flex;
  justify-content: space-between;

  .link-back {
    font-size: 1.17em;
    font-weight: bold;
    text-decoration: none;
    color: #00aaff;
    cursor: pointer;

    span {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .div-back {
    display: inline;
    border-left: 2px solid #00aaff;
    margin-right: 5px;
  }

  .right-buttons {
    float: right;
    margin: 2px 0.5em;
  }
}

.form-change-plan {
  margin-top: 20px;

  label {
    display: block;
  }
}

.company-detail {
  border: 1px solid #ccc;
  min-height: 300px;
  padding: 25px;
  background-color: #fff;
  margin-top: 25px;

  .form-company-details {
    display: flex;
    justify-content: space-around;

    .form-company-colum {
      display: flex;
      flex-direction: column;
      width: 40%;

      .form-company-input-container {
        margin-bottom: 2em;

        input {
          padding: 0%;
        }

      }

      .welcome-email {
        display: flex;
      }
      .valida-nit {
        display: flex;
      }
    }
  }

  .save-button {
    width: 200px;
    margin: -10px auto 0;
  }
}

.loader {
  height: 25px;

  .lds-ring {
    width: 25px;
    height: 25px;

    div {
      width: 25px;
      height: 25px;
    }
  }
}

.header-company-logs{
  margin-bottom: 1em;
}

.table-company-logs{
  .table-pagination{
    margin-top: 1em;
    margin-bottom: 2em;
  }
}

.change-user-email {
  margin-top: 7px;
}