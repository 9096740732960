.side-menu {
  -moz-box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.6);
  ­-moz-­transition: all 0.5s ease;
  -­o-­transition: all 0.5s ease;
  -webkit-box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.6);
  -webkit-­transition: all 0.5s ease;
  align-items: center;
  background-color: #00aaff;
  box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  margin-right: 5%;
  position: fixed;
  transition: all 0.5s ease;
  width: 15%;
  
  

  .header-menu {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10%;
    position: relative;

    .avatar {
      margin-bottom: 30px;
      margin-right: 10px;
      max-width: 40%;
      text-align: center;

      img {
        border-radius: 50%;
      }
    }

    .hamburguer {
      width: 20px;
      cursor: pointer;
      position: absolute;
      right: 10%;
      top: 0%;

      span {
        border-bottom: 2px solid #ffffff;
        display: block;
        width: 100%;
        margin-top: 5px;
      }
    }
  }

  .nav-menu {
    bottom: 0;
    display: inline-block;
    left: 0;
    overflow: auto;
    padding-top: 10%;
    top: 0;
    width: 100%;

    &::-webkit-scrollbar{
      width: 6px;
    }
    &::-webkit-scrollbar-track{
      border-radius: 10px;
    } 
  
    &::-webkit-scrollbar-thumb{
      border-radius: 10px;
      background: #00aaff;
    } 

    ul {
      padding: 0;

      li {
        cursor: pointer;
        border-left: 4px solid transparent;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }

        &.active {
          background-color: rgba(0, 0, 0, 0.1);
          border-left: 4px solid #97bf19;
        }

        a {
          color: #ffffff;
          font-family: "Muli", sans-serif;
          text-decoration: none;
          font-size: 14px;
          display: flex;
          padding: 5%;

          span {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .bottom-logo {
    margin-top: auto;
    margin-bottom: 20%;
  }
}

.side-menu.is-close {
  width: 3%;

  .header-menu {
    flex-direction: column-reverse;

    .avatar {
      width: 100%;
      margin: 0;
    }

    .nameAvatar {
      display: none;
    }

    .hamburguer {
      position: relative;
      right: 0;
      margin: 10px 0 20px;

      span {
        border-bottom: 2px solid #ffffff;
        display: block;
        width: 100%;
        margin-top: 5px;
      }
    }
  }

  .nav-menu {
    ul {
      li {

        a,
        label {
          justify-content: center;
          padding: 25%;

          span {
            display: none;
          }
        }
      }
    }
  }

  .bottom-logo {
    padding: 10%;
  }
}

.icon-img {
  width: 15px;
}

.icon-mas {
  margin-right: 8px;
  margin-bottom: -1px;
}

ul li input[type="radio"] {
  display: none;
}

ul li label {
  color: #ffffff;
  font-family: "Muli", sans-serif;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  padding: 5%;
  cursor: pointer;

  span {
    margin-left: 10px;
  }
}


ul li input[type="radio"]:checked~.options {
  height: auto;
  display: block;
  min-height: 40px;
}

ul li input[type="radio"]:checked+label {
  background-color: rgba(0, 0, 0, 0.2);
  border-left: 4px solid #97bf19;
  margin-left: -4px;
}

.nameAvatar {
  color: #fff;
  font-size: 16px;
  width: 100%;
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -50%;
  margin-top: 10px;
}

.group-list a, 
.group-list label {
  background-color:  rgba(0,0,0,0.1); 
}
.group-list a:focus, 
.group-list a:hover, 
.group-list label:focus, 
.group-list label:hover { 
  background-color:  rgba(0,0,0,0.2); 
}
.group-list .active {
  background-color: transparent;
  border-left: 0;

  a span {
    color: #ffc933;
  }

}
/**
 * Styling second level list items
 */
.sub-group-list a, 
.sub-group-list label {
  padding-left: 2rem;
  background-color: rgba(0,0,0,0.3);
}
.sub-group-list a:focus, 
.sub-group-list a:hover, 
.sub-group-list label:focus, 
.sub-group-list label:hover { 
  background-color: rgba(0,0,0,0.4);
}
.sub-group-list .active {
  background-color: transparent;
  border-left: 0;

  a span {
    color: #ffc933;
  }
}
/**
 * Styling third level list items
 */
.sub-sub-group-list a, .sub-sub-group-list label {
  padding-left: 6rem;
  background: #454545;
  box-shadow: inset 0 -1px #575757;
}
.sub-sub-group-list a:focus, .sub-sub-group-list a:hover, .sub-sub-group-list label:focus, .sub-sub-group-list label:hover { background: #333333; }
/**
 * Hide nested lists
 */
.group-list, .sub-group-list, .sub-sub-group-list {
  height: 100%;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height .5s ease-in-out;
  transition: max-height .5s ease-in-out;
}
.nav__list input[type=radio]:checked + label + ul { /* reset the height when checkbox is checked */
max-height: 1000px; }

label > span {
  float: right;
  -webkit-transition: -webkit-transform .65s ease;
  transition: transform .65s ease;
}

.nav__list input[type=radio]:checked + label > i.icon-arrow-rigth {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}