.input-container {
  //display: -ms-flexbox; /* IE10 */
  //display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.text-cont {
  justify-content: center;
}

h3 {
  color: #00aaff;
  display: contents;
}

input,
select {
  border-left: none;
  border-right: none;
  border-top: none;
  font-family: 'Muli', sans-serif;
  font-size: 16px;
  padding-left: 30px;
  padding-bottom: 7px;
  border-color: #737373;
  border-width: 1px;
  width: 90%;

  &:focus {
    outline: none;
  }
}

.icon {
  padding: 10px;
  color: white;
  text-align: center;
  position: absolute;
}

.link-psw {
  font-size: 14px;
  color: #00aaff;
}

.sii.sii-user {
  background: url('../../assets/icons/user.svg') no-repeat;
}

.sii.sii-lock {
  background: url('../../assets/icons/lock.svg') no-repeat;
}

button {
  &:focus {
    outline: none;
  }
  &.link-ms {
    border: none;
    border-radius: 0;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    color: #97bf19;
    width: auto;
  }
}
