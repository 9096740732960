.modal {
  position: fixed;
  width: 800px;

  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  background: white;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  filter: blur(0);
  /*transform: scale(1);*/
  opacity: 1;
  visibility: visible;
  border-radius: 5px;
}
.modal-parent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background: #040006 0% 0% no-repeat padding-box;
  opacity: 0.5;*/
  background: rgba(4, 0, 6, 0.5);
  z-index: 100;
}
.modal.off {
  opacity: 0.5;
  visibility: hidden;
  filter: blur(8px);
  transform: scale(0.33);
  box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2);
}
@supports (offset-rotation: 0deg) {
  offset-rotation: 0deg;
  offset-path: path('M 250,100 S -300,500 -700,-200');
  .modal.off {
    offset-distance: 100%;
  }
}
@media (prefers-reduced-motion) {
  .modal {
    offset-path: none;
  }
}
.modal .h2 {
  border-bottom: 1px solid #ccc;
  padding: 1rem;
  margin: 0;
  color: #37465a;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
  font-weight: bold;
}
.modal .content {
  padding: 1rem;
  padding-left: 8%;
  padding-right: 8%;
}
.modal .actions {
  /*border-top: 1px solid #ccc;
  background: #eee;
  padding: 0.5rem 1rem;*/
  padding: 2rem 1rem;
  text-align: center;
}
/*.modal .actions button {
  border: 0;
  background: #78f89f;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  line-height: 1;
}*/
#centered-toggle-button {
  position: absolute;
}

select {
  background-image: url(siigo-flecha-abajo.svg); /*aquí deberás escribir la ruta de la imagen que utilizarás como flecha del desplegable*/
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 10px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  text-align: left;
  padding-left: 0px !important;
  padding-right: 20px;

  color: #747474;
}
select::-ms-expand {
  display: none; /*Evita que se muestre la flecha por defecto en versiones de IE*/
}

.slModal {
  border-bottom: 1px solid #747474;
}

.colGrid {
  width: 400px;
  max-width: 90%;
}

.labelModal {
  display: block !important;
  color: #00aaff;
}

.inputFocus {
  border-bottom: 1px solid #00aaff;
}

.inputError {
  border-bottom: 1px solid #f7274e;
}

.labelError {
  display: block !important;
  color: #f7274e;
}
.inputOk {
  border-bottom: 1px solid #7ac23a;
}

.labelOk {
  display: block !important;
  color: #7ac23a;
}

.h3Modal {
  display: block;
  margin-bottom: 15px !important;
  margin-top: -10px;
}

.title-process{
  color: #f7274e;
}

