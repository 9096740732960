.divDynamicFilter {
    display: block !important;
    padding-bottom: 0px;
    margin-top: 40px;
    background-color: #fff;
    border-radius: 10px;
}

.gridDateFilter {
    width: 100%;
    display: grid;
    grid-template-columns:80% 10% 10%;
}

.itemDynamicDate {
    width: 80%;
    position: relative;
    justify-self: left;
    align-self: center;
}

.inputFilter {
  padding-left: 0;
  // border-bottom: 3px solid #747474!important;
}

.divTable {
  //overflow-x: auto;
  width: 100%;
}

.InputFromTo{
  display: flex;
  margin-right: 10px;
}

.itemSFDate {
  margin-right: 20px;
}

.divFilter{
  display: flex !important;
  align-items: center;
}

.input-container-accountant{
  margin-right: 15px;
}