.msg-list{

    position: absolute;
    top: 130px;
    width: 100%;
    min-height: 125px;
    left: 0px;
    background-color: white;


    li{
        border-bottom: 1px solid #F4F4F4;
        padding: 20px;

        .msg-menssage-info{
            height: 100px;
            width: 100%;
        }

        .msg-notificacion-info{
            height: 150px;
            width: 100%;
        }

        h4{
            margin: 0px;
        }

        label{
            color: inherit !important;
            padding: 0px;
        }
    }
}
