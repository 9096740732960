.borderbottom-sutil{
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
}


.alojamiento {
    &-espacio {
        -webkit-box-shadow: 0px !important;   
        box-shadow:  0px   !important;   
        border-radius: 2px;
        padding: 10px;
        width: 325px;
    }

    &-duenio-descripcion > h4{
      margin-top: 2px;  
      margin-bottom: 2px;  
    }
}

.anfitrion-container{
    padding-top: 1px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: white;
    margin-top: 2px;
}

.row-cerrar-sesion{
    margin-top: 15px;
}

#savePerfil, #idNombreMorada, #textDescripcionMorada{
    width: 100%;
}