.modal {
  position: fixed;
  width: 720px;
  max-width: 90%;
  background: white;
  border: 1px solid #ccc;
  top: 50%;
  left: 50%;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  filter: blur(0);
  opacity: 1;
  visibility: visible;
  border-radius: 5px;
}
.modal .h2 {
  border-bottom: 1px solid #ccc;
  margin: 0;
  color: #37465a;
  font-size: 20px;
  text-align: left;
  letter-spacing: 0;
  font-weight: bold;
  padding-left: 8%;
  padding-right: 8%;
  padding-bottom: 0%;
}

.container-button-complements {
  float: right;
  margin: 2px 20px;
}
.modal-w2c {
  .container-notification {
    position: absolute;
    top: 150px;
    right: 459px;
  }
  .closeModal {
    color: #009dff;
    width: 15px;
  }
  hr {
    border: 1px solid #e8e8e8;
  }
  .container-content-w2c {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    p {
      height: 24px;
      width: 540px;
      color: #37465a;
      //font-family: "Nunito Sans";
      font-size: 17px;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 32px;
      margin-top: 16px;
    }
    .add-button {
      background-color: #7ac23a;
      color: white;
      border-radius: 8px;
      cursor: pointer;
      width: 90px;
      padding: 5px;
      font-size: 15px;
    }
    .disabled-button {
      color: white;
      border-radius: 8px;
      cursor: pointer;
      width: 90px;
      padding: 5px;
      font-size: 15px;
      background-color: #d8d8d8;
      color: grey;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
  }
  .label-state {
    font-size: 12px;
    color: grey;
  }
  .mandatory-style {
    color: rgb(255, 165, 50);
    font-weight: 700;
    right: 0px;
    font-size: 15px;
  }
  .container-components {
    margin: 10px 0px 20px 0px;
    height: 100px;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    .component {
      height: 100%;
      width: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .container-main-input {
    margin-bottom: 24px;
    width: 100%;
    height: 100%;
    siigo-input-atom {
      width: 100%;
    }
  }
  .group-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;
    siigo-input-atom {
      width: 100%;
    }
    .group-input-left {
      width: 100%;
      margin-right: 12px;
      siigo-select-atom {
        width: 84%;
      }
      select {
        width: 100%;
      }
    }
    .end-align {
      display: flex;
      align-items: flex-end;
    }
    .group-input-right {
      width: 100%;
      margin-left: 12px;
    }
  }
  .container-buttons {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    justify-content: end;
  }
  .float-lf {
    .cancelButton {
      margin-right: 12px;
    }
    .addButton {
      margin-left: 12px;
    }
  }
}
