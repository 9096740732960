%shead{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    height: 54px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.sheader{
    @extend %shead;
    background-color: #009DFF;
}

.subsheader{
    @extend %shead;    
    background-color: #FFF;
    box-shadow: 0 4px 6px -6px black; 
    padding: 0px 10px 0px 10px;

    &-menu{

    }
}



#logo{
    height: 50px;
    width: 50%;
}

.container-avatar{
    text-align: -webkit-right;
    text-align: -moz-right;
    text-align: -o-right; 
    text-align: -ms-right;
    margin-right: 20px;
}

.avatar {
    &-header{
        border-radius: 50%;
        width: 45px;
        margin-top: 5px;
    }

    &-circle{
        width: 45px;
        height: 45px;
        line-height: 45px;
        border-radius: 50%;
        font-size: 20px;        
        color: #009DFF;
        font-weight: 900;
        text-align: center;
        background: #fff;
        margin-left: 20px;
        
        &-border{
            border: 1px solid #009DFF;
            width: 50px;
            height: 50px;
            line-height: 50px;
            font-size: 25px; 
        }
    }
}