.slFilter {
  border-bottom: 3px solid #747474 !important;
  background: #fff;
}

.inputFilter {
  padding-left: 0;
}

.btnBuscar {
  margin-right: 5px;
}

.divTable {
  //overflow-x: auto;
  width: 100%;
}
.rowModal {
  margin-bottom: 5px;
}

.h3Modal {
  margin-top: 5px;
}
.icon-question{
  position: absolute;
  color: silver;
  margin-top: 1.5px;
}
.text-info-data{
  margin-left: 17px;
  color: silver;
}
