.inputs-cashiers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    .cashier {
      display: flex;
      flex-direction: column;
      gap: 10px;

      input {
        padding: 0;
      }
    }
  }