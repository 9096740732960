.siiger-alert-message{
    height: 80px;
    border: 2px solid #61b045;
    border-radius: 5px;
    padding: 10px 20px 20px 20px;
    color: #37465a;    
    background-color: floralwhite;

    &-title {
        font-size: 18px;
        margin: 0px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-weight: 800;
    }

    &-label {
        font-size: 15px;
    }
}