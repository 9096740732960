.container-button-complements{
  float: right;
  margin: 2px 20px;
}
.modal-complements{
  .container-notification{
    position: absolute;
    top: 150px; 
    right: 459px;
  }
  .closeModal{
    color: #009dff;
    width: 15px;
  }
  siigo-select-atom{
    width: 100%;
    margin: 15px 0px;
  }
  .line-component-complement{
    border: 1px solid #e8e8e8;
    margin: 10px 0px;
  }
  .container-content{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    p{
      margin: 0;
      font-size: 16px;
    }
    .add-button{
      background-color: #7ac23a;
      color: white;
      border-radius: 8px;
      cursor: pointer;
      width: 90px;
      padding: 5px;
      font-size: 15px;
    }
    .disabled-button{
      color: white;
      border-radius: 8px;
      cursor: pointer;
      width: 90px;
      padding: 5px;
      font-size: 15px;
      background-color: #d8d8d8;
      color: grey;;
      &:hover{border: 1px solid rgb(105, 105, 105);}
    }
  }
  .container-components{
    margin: 10px 0px 20px 0px;
    height: 100px;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    .component{
      height: 100%;
      width: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .status {
      text-align: center;
    }

    .container-buttton-component{
      width: 75%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .button{
        display: flex;
        justify-content: space-between;
        height: 30px;
        width: 100px;
        align-items: center;
        cursor: pointer;
        .delete{
          border: 1px solid #cfcfcf;
          border-radius: 4px 0px 0px 4px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          p{
            color: #009DFF;
            font-weight: 700;
          }
        }
        .flecha{
          border: 1px solid #cfcfcf;
          border-radius: 0px 4px 4px 0px;
          position: relative;
          height: 100%;
          width: 29%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid #cfcfcf;
          svg{
            color: #009DFF;
          }
          .button-config{
            position: absolute;
            background-color: white;
            border-radius: 4px;
            width: 160px;
            top: 31px;
            right: 19px;
            padding: 10px 6px;
            box-shadow: 1px 1px 4px #c4c0c0;
            &:hover{
              background-color: #dbdbdb;
              cursor: pointer;
            }
          }
        }
      }
      .disabled {
        background-color: #d8d8d8;
        p {
          color: gray !important;
        }
      }
    }
  }
  .container-not-components{
    margin: 10px 0px 20px 0px;
    height: 100px;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      margin-right: 10px;
    }
  }
  .container-add-complements{
    height: 200px;
    .input-add-components{
      position: relative;
      width: 100%;
    }
    p{font-size: 16px;}
    .float-lf{float: right;}

    .srchList{
      background: white;
      border-radius: 0 0 10px 10px;
      position: absolute;
      width: 100%;
      top: 48px;
      z-index: 1000;
      box-shadow: 0 0px 4px grey;
      ul{
        li{
          padding: 10px 12px;
          border-radius: 0 0 10px 10px;
          &:hover{
            background-color: #d3d3d3;
            cursor: pointer;
          }
        }
      }
    }
  } 
}

.swal2-actions {
  gap: 10px;
}