h1{ 
    font-family: 'icomoon', sans-serif !important;
}

hr.siiger {
    border: solid 0px !important;
    color: white;
    background-color: white !important;
    margin: 10px;
}

.ContainerSiigo {
    margin: auto;

    a{
        text-decoration: none !important; 
    }

    .col{
        padding-right: 0px !important;
    }    
}

.container.siiger { 
    width: 100% !important;
    max-width: 800px;
    margin: auto;
}

.row {
    display: flex;
}

/**/

.text-center{
    text-align: center;
}

.align-center{
    display : flex;
    align-items : center;
    justify-content: center !important;
}

/**/

.scard{
    margin: 10px;
    padding: 30px;
    gap: 10px;
    min-height: 30px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: 4px 4px 4px -2px rgba(0,0,0,0.05);   
    box-shadow: 4px 4px 4px -2px rgba(0,0,0,0.05);   

    &-title{
        width: 300px;
        height: 44px;

        /* Headings/H2 */

        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 900;
        font-size: 1.6rem;
        line-height: 44px;
        /* identical to box height */

        text-align: center;
        /* Secondary/Dark Blue */

        color: #37465A;
        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px;
    }

    &-mas-gap{
        gap: 35px;
    }

}


/* icon */

.fa-star{
    color: #FFA532;
}

.fa-house-chimney-user{
    color: #C4E8FF; // #009DFF;
    font-size: 10rem;
}

.width-50{
    max-width: 50%;
    width:  50%;
}

.width-100{
    max-width: 100%;
    width:  100%;

}