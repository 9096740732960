#searchBuscarViajesButton{
    width: 100%;
}

label{
    color: rgb(55, 70, 90);
    line-height: 24px;
    font-size: 16px;
    font-family: NunitoSans, sans-serif !important;
}

.col.SiigoNumber {
    text-align: center;
    display : flex;
    align-items : center;
    justify-content: center;
}

