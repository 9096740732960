.siiger-tag{
    position: absolute; 
    border-radius: 15px;   
    padding-left: 10px;
    padding-right: 10px;
    min-width: 50px;

    &-label{
        font-size: 11px;
        color: white;
    }

    &-success{
        background-color: #619B2E;
    }

    &-waiting{
        background-color: #FFA532;
    }

    &-refused{
        background-color: #D42143;
    }      

    &-finish{
        background-color: #8B00F7;
    }     

    &-canceled{
        background-color: black;
    }     

    &-neutral{
        background-color: coral;
    }
}
