.slFilter {
  border-bottom: 1px solid #747474;
}

.inputFilter {
  padding-left: 0;
  // border-bottom: 3px solid #747474!important;
}

.btnBuscar {
  margin-right: 5px;
}

.btnLoadInterSubs {
  height: 30px !important;
  width: 30px !important; 
}

.divLoadSubs {
  border: 5px solid #69aa29 !important;
  border-color: #69aa29  transparent transparent transparent !important;
}

.divTable {
  //overflow-x: auto;
  width: 100%;
}
.rowModal {
  margin-bottom: 5px;
}

.h3Modal {
  margin-top: 5px;
}

.gridSearchFilter {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 20% 44% 11% 5%;
}

.gridSearchFilter2 {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 20% 49% 11%;
}

.itemSFDate{
  width: 80%;
  position: relative;
  justify-self: left;
  align-self: center;
}
.titleSF{
  padding-bottom: 20px;
  h3{
    color: rgb(71, 69, 69) !important;
  }
  
}
.itemSFInput{
  width: 98%;
  border: 1.5px solid rgb(138, 135, 135) !important;
  border-radius: 17px;
  position: relative;
  justify-self: right;
  align-self: center;
}

.itemDownload{
  justify-self: right;
  align-self: center;
  p{
    color: #69aa29;
  }
}

.itemClear{
  justify-self: right;
  align-self: center;
}

.iconSearch{
  padding: 10px;
  margin: 0px;
}

.imputSearchFilter{
  border:none;
  padding-left: 3px !important;
  padding-bottom: 0px !important;
  margin: 0px;
  background-color: rgba(241, 235, 235, 0) !important;
}

.datePickerFilter{
  padding-left: 0 !important;
  padding-right: 18px !important;
  background-color: rgba(241, 235, 235, 0) !important;
  border-width: 1.5px !important;
}

.gridModalEditCC {
  display: grid;
  padding: 15px;
  grid-template-columns: 20% 60% 20%;
}

.gridModalEditCC2{
  display: grid;
  padding: 15px;
  grid-template-columns: 20% 30% 50%;
}

.item1Modal {
  justify-self: right;
  align-self: center;
}
#link:disabled, #pin:disabled{
  background: white !important;
}

#link:hover, #pin:hover{
  cursor: not-allowed;
}
.item2Modal {
  justify-self: center;
  align-self: end;
  width: 90%;
}

.item2Modal2 {
  justify-self: center;
  align-self: end;
  width: 68%;
}

.btnAddP{
  background: white;
  float: left;
  color: #97bf19 !important;
  border: 1px solid #7ac23a !important;
  &:hover {
    color: #ffffff !important;
    background: #69aa29 !important;
    border-color: transparent !important;
  }
  margin-right: 5%;
}
.errorState {
    color: red !important;
    font-weight: bold;
    font-style: italic;
}

.btnDownload {
    margin: 0 0 2em 0;
}

.table-components{
    overflow-x: scroll;
}


.warningState{
  color: darkorange !important;
  font-weight: bold;
  font-style: italic;
}

.successState{
  color: green !important;
  font-weight: bold;
  font-style: italic;
}


.input-container-accountant {
  margin-bottom: 30px;

  width: 100%;

  input {
    padding-left: 0px;
    padding-bottom: 0px;
    display: block;
    color: #747474;
    margin-top: 10px;
  }
  label {    
    color: #747474;
    font-size: 13px;
  }

  select{
    background-color: white;
    margin-top: 3px;
  }
}

.inputFocus {
  border-bottom: 1px solid #00aaff;
}


.labelModal {
  display: block !important;
  color: #00aaff;
}

.inputFocus {
  border-bottom: 1px solid #00aaff;
}

.inputError {
  border-bottom: 1px solid #f7274e;
}

.labelError {
  display: block !important;
  color: #f7274e;
}
.inputOk {
  border-bottom: 1px solid #7ac23a;
}

.labelOk {
  display: block !important;
  color: #7ac23a;
}

.uxDetailContainer
{
  margin: 8px 0px;
}

.divTableModal{
  .container{
    margin: 0;
  }

  .container .table-components table{
    margin-top: 0;
  }


}

.massiveActionsContainer{

  margin-left: 20px;
  text-align: center;

  label{
    display: inline-block;
    color: #747474;
  }

  input[type="checkbox"]{  
    display: inline-block !important;
    width: 20px;
  }
}

.chResendEmail{
    display: inline-block !important;
    width: 20px;  
}
.coustomTab, .table-report{
  margin-top: 6px !important;
  margin-bottom: 2em !important;
}

.divSearchFilter{
  display: block !important;
  padding-bottom: 0px;
  margin-top: 40px;
  // background-color: #fff;
  border-radius: 10px;
}

.massiveActions{
  margin-left: 20px;
  display: flex;
  align-items: center;

  .input-container-accountant{
    margin-right: 15px;
  }

  .submitMassiveActions{
    width: 140px;
    height: 35px;
  }
}


.InputFromTo{
  display: flex;
  margin-right: 10px;
}


.table thead a.actions{
  text-indent: 1px !important;
  margin-right: 15px;
}