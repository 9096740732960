.menu-icon-button {
    border-bottom: 2px solid white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 6px;

    .fa-solid{
        color: #D8D8D8;
        font-size: 20px;    

        // &.selected{
        //     color: #009DFF;
        // }
    }

    &.selected{
        border-bottom: 2px solid #009DFF;

        .fa-solid {
            color: #009DFF;
        }
    }
}