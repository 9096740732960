.change-user-email {
    .content {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .inputs {
        margin-bottom: 10px;
    }
    input[id=userCode] {
        padding-left: 0;
        padding-bottom: 0;
    }

    .actions {
        display: flex;
        justify-content: end;
        gap: 5px;
    }
}