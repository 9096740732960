.card-report {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    border-radius: 20px;
    margin-top: 10px;
}

.card-item {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: all 0.3s ease;
    width: 100%;
    margin-top: 10px;
    border: 2px solid #f1f4f9;
    border-radius: 10px;
}

.card-item:hover {
    border-color: #f1f4f9;
    background-color: rgba(128, 128, 128, 0.5); /* gris opaco */
    transform: scale(1.05); /* aumentar el tamaño en un 10% */
}

.link-card {
    text-decoration: none;
    color: inherit
}
  
.card-report:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
  
.container-report {
    padding: 1.25rem
}

.grid25 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    width: 95%;
}

.li-item {
    list-style-type: circle;
}