.top-bar {
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  padding-left: 20%;

  .search-bar {
    form {
      display: flex;
    }
    input {
      border: transparent;
      color: #747474;
      padding-left: 10px;
    }
  }

  .container {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px 0;

    .account {
      padding: 0 20px;
      height: 30px;
      border-left: 2px solid #d3d4d7;
      padding-left: 15px;
      padding-top: 7px;

      label[for=emailItem1]{
        display: flex;

        .imgPais2 {
          width: 25px;
          margin-left: -5px;
          margin-right: 5px;
        }
      }
      a {
        color: #747474;
        font-size: 14px;
        text-decoration: none;
        position: relative;

        i {
          margin-left: 15px;
        }
      }
    }
    .right-box {
      align-items: center;
      display: flex;
      float: right;

      .add-item {
        border-right: 1px solid #f1f4f9;
        padding-right: 20px;
      }
    }
  }
}

div input[type='checkbox'] {
  display: none;
}

div input[name='emailItem'][type='checkbox']:checked ~ .submenu {
  position: absolute;
  display: block;
}

div input[name='countryItem'][type='checkbox']:checked ~ .submenuCountry {
  height: auto;
  position: absolute;
  display: block;
  z-index: 2000;
}

.account span {
  margin-right: 10px;
  color: #747474;
  font-size: 14px;
  position: relative;
  &:hover {
    color: #00aaff;
    cursor: pointer;
  }
}

.countries span {
  margin-right: 10px;
  color: #747474;
  font-size: 14px;
  position: relative;
  &:hover {
    color: #00aaff;
    cursor: pointer;
  }
}

.submenu {
  background-color: #fff;
  border-top: 3px solid #00aaff;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  display: none;
  height: 88%;
  margin-left: -100px;
  margin-top: 25px;
  padding-bottom: 15px;
  padding-top: 15px;
  position: absolute;
  z-index: 1000;

  li {
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    &:hover {
      background-color: #f1f4f9;
    }
  }
}

.submenuCountry {
  position: absolute;
  display: none;
  background-color: #fff;
  width: 70%;
  border-top: 3px solid #00aaff;
  padding-top: 5px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);

  li {
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    &:hover {
      background-color: #f1f4f9;
    }
  }
}

.logout {
  color: #f7274e !important;
}
.icon-flecha-abajo {
  padding-right: 10px;
  width: 10px;
}

.countries {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 220px;

  label{
    padding: 0px;
  }

  a {
    color: #747474;
    font-size: 14px;
    text-decoration: none;
    position: relative;

    img {
      width: 20px;
      margin-left: 5px;
    }
    i {
      margin-left: 15px;
    }
  }
}

select {
  background-image: url(siigo-flecha-abajo.svg); /*aquí deberás escribir la ruta de la imagen que utilizarás como flecha del desplegable*/
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 10px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  text-align: left;
  padding-left: 0px !important;
  padding-right: 20px;
  border-bottom: none;
  color: #747474;
}
select::-ms-expand {
  display: none; /*Evita que se muestre la flecha por defecto en versiones de IE*/
}

.imgPais {
  width: 20px;
  margin-left: -5px;
  margin-right: 5px;
}

.selectMenu {
  position: absolute;
  //display: none;
  background-color: #fff;
  margin-top: 25px;
  width: 12%;
  border-top: 3px solid #00aaff;
  z-index: 1000;
  padding-bottom: 15px;
  padding-top: 15px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);

  option {
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    &:hover {
      background-color: #f1f4f9;
    }
  }
}


.btn-logout {
  color: white;
  background: #ffc933;
  border: 1px solid #ffc933;
  padding: 5px 20px;
  border-radius: 14px;
}

.align-button {
  text-align: center;
}

hr {
  border: solid 1px !important;
  color: #d2d2d2;
  margin: 10%;
}

.menu-admin {
  text-align: left;
}
