.campaigngrid {
    display: flex;
    flex-direction: row;
}
.campaign-sidea {
    width: 100%
}
.campaign-sideb {
    padding: 20px
}

.campaign-actual-image {
    max-width: 85%;
}