.buttonGenerate {
}
.divCopy {
  margin-left: 25px;
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  padding-top: 8px;

  span {
    display: none;
  }
  &:hover span {
    background-color: #000;
    color: #fff;
    padding: 5px 20px 5px 5px;
    display: block;
    z-index: 100;
    margin: 10px;
    margin-left: -125px;
    width: 250px;
    position: absolute;
    text-decoration: none;
  }
}

.cuntrySelectFlag{
  padding-right: 10px;
  width: 25px;
}
.flagSelect{
  display: flex
}
.flagSelect>select{
  width: 85%;
}

.grid85 {
  display: grid;
  grid-template-columns: 85% 7%;
}
.grid50 {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 95%;
}

.mbottom20 {
  margin-bottom: 20px;
}

.inputLink {
  padding-top: 10px;
  padding-left: 10px !important;
  padding-right: 10px;
}

.inputJWT {
  width: 100%;
}

h2,h4 {
  color: #00aaff;
}
.linkLabel, .expDateLabel, .contactDataLabel{
  margin-top: 20px;
}
h4{
  margin: 0
}