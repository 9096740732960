.ic-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logoContainer {
  max-width: 220px;
  margin-bottom: 40px;
  min-width: 150px;
}

p {
  color: #737373;
  margin: 0;
}
