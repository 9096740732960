.inputTextAreaRefund{
    width: 100%;
    height: 80px;
    resize: none;
    border: lightgray 1px solid;
}

.inputTextAreaRefund:focus-visible{
    outline-color: darkgray;
}
.span-danger {
    font-size: 12px;
    color: red;

}
.loaderRefund{
    height: 100%;
    width: 100%;
    background: black;
    opacity: .5;
    z-index: 200;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}