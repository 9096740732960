.border-select {
  border-bottom:1px solid #ccc;
 
}

.fila {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap:5px;
  
}

.columna1 {
 grid-column: 1 / span 0.6 ;
}

.columna2 {
    grid-column: 1 / span 1.5;
}

.ml-4 {
  margin-left:20px;
}

.mr-4 {
  margin-right:20px;
}

.mt-2 {
    margin-top:5px;
    
    
}

.mt-1 {
  margin-top:3px;
}

.mr-2 {
  margin-right:5px;
}

.siigoInput {
    line-height: 24px !important;
}
