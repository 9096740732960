
#emailInput{
    width: 600px;  
}

#searchEmailButton, #clearInputsButton{
    margin-right: 5px;
}

h1 {        
    color: #009DFF;
    margin-bottom: 0px;
}

h5 {
    margin-top: 5px;
}

.datePickerContainer{
    .datePicker{
        width: 300px;
    }
}

.controlsSectionContainer {
    display: flex;
    div {
        display: flex;   
        margin-right: 20px;     
    }    
}

.whiteBackgroundSection {
    padding: 15px;
    margin-top: 25px;
    background-color: #fff;
    border-radius: 10px;
}

.datePickerContainer .datePicker{
    width: 500px;
}

.container .table-report{
    margin-bottom: 20px !important;
}