.siicon-process {
    font-family: 'icomoon' !important;
  
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.icon-excel::before {
        content: '\eb76';
        margin:0em 0.5em 0em;
        color: #7ac23a;
        font-size: 2em;
      }
      &.icon-excel:hover::before {
        content: '\eb76';
        cursor: pointer;
        color: #69aa29;
      }
      &.icon-load::before{
        content: '\ec3d';
        margin:0em 0.5em 0em;
        color: #7ac23a;
        font-size: 2em;
      }
      &.icon-load:hover::before {
        content: '\ec3d';
        color: #69aa29;
      }
      &.icon-deleteX::before {
        content: '\ea21';
        font-size: 1.5em;
        color:red;
        cursor: pointer;
      }
}  

.buttons {
  background: #00aaff;
  color: #ffffff;
  //text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  box-shadow: none;
  padding: 5px 0;
  border-radius: 25px;
  font-family: 'Muli', sans-serif;
  //width: 100%;
  padding: 5px 20px;
  border: 1px solid transparent;
  cursor: pointer;
  float: right;
  margin-top: -2.7em;

  &.green-process {
    color: #7ac23a;
    background: transparent;
    border: 1px solid #7ac23a;
    padding: 5px 20px;
    border-radius: 14px;
    align-self: center;

    &:hover {
      background-color: #7ac23a;
      border-color: transparent;
      color: #ffffff;
    }
  }
}
.description-green{
    vertical-align: 8px;
    color: #7ac23a ;
}
.marginLeft {
  margin-left: 10px;
}
.column {
    padding: 20px;
}

.column-description{
  padding-top: 0.5em;
}

.rownd {
  display: flex;
}

.rows {
  display: flex;
}

.divInformation{
    padding: 15px;
    margin-top: 25px;
    background-color: #fff;
    border-radius: 10px;
}

.input-process{
  opacity: 12;
  width: 10.3em;
  height: 2em;
  display: inline-block;  
  vertical-align: 8px;
  color: #7ac23a ;
  cursor: pointer;
  padding: 0px;
}

#loadExcel::before{
  content: "Subir listado productos";
  text-decoration: underline;
}

.title{
  color: #7ac23a ;
  font-size: 18px;
}

.bulk-load{
  font-size: 24px;
  color: #00aaff;
}

.load-excel{
  color: #747474;
  font-weight: bold;
  font-size: 17px;
  padding-left: 0.5em;
  vertical-align: 6px;
}

.hModal {
  display: block;
  margin-bottom: 15px !important;
  margin-top: -10px;
  text-align: center;
  color: #747474;
  font-weight: bold;
}

.input-containers {
  margin-bottom: 30px;

  width: 100%;
}

.downloadExcelContainer span
{
  cursor: pointer;
  text-decoration: underline;
}
.grid-user-edit {
  display: grid;
  grid-template-columns: 1fr;
  padding: 15px;
  margin-top: 25px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.link-password {
  font-size: 12px;
}
.ckeckcontainer {
  position: relative;
  .labelcheckadmin {
    position: absolute;
    margin-left: 20px;
    margin-top: -4px;
  }
}
.flex-init {
  display: flex;
  .sub-title {
    flex: 1;
  }
}
h3 {
  a {
    color: #00aaff;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}
input,
select {
  font-family: "Muli", sans-serif;
  font-size: 16px;
  padding-left: 30px;
  padding-bottom: 7px;
  border-color: currentcolor currentcolor #737373;
  border-left: 1px #737373;
  border-width: 1px !important;
  border-right: 1px #737373;
  border-top: 1px #737373;
  width: 90%;
}

.a-button {
  background: #0af;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  box-shadow: none;
  padding: 5px 0;
  border-radius: 25px;
  font-family: "Muli", sans-serif;
  border: 1px solid transparent;
  cursor: pointer;
  text-decoration: none;
  float: right;
  margin-top: -10px;
}
.a-button.red-button {
  border: 1px solid #f70e0e !important;
  background: transparent;
  padding: 9px 22px !important;
  align-self: center;
  color: #f70e0e !important;
  font-size: 13px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {  
  -moz-appearance: textfield;
}
.mtop-select {
  margin-top: -7px;
}

.titleError {
  position: relative;
  text-align: center;
  font-weight: bold;
  color: #bc3545;
}

.font-error {
  font-weight: bold;
}

.container {
  display: flex;
  flex-direction: column;
}

title-noty {
  text-align: center;
  margin-bottom: 20px;
}