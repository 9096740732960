.modal {
    position: fixed;
    width: 700px;
    max-width: 90%;
    background: white;
    border: 1px solid #ccc;    
    top: 50%;
    left: 50%;     
    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    filter: blur(0);    
    opacity: 1;
    visibility: visible;
    border-radius: 5px;
    animation-name: modalFade;
    animation-duration: .3s;

}



.modal-parent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: #040006 0% 0% no-repeat padding-box;
  opacity: 0.5;*/
    background: rgba(4, 0, 6, 0.5);
    z-index: 100;
   
}

@keyframes modalFade {
    0% {  width: 500px; }
    25% { width: 550px; }
    50% { width: 600px; }
    75% { width: 650px; }
    100% {  width: 700px; }
  }
  

.modal.off {
    opacity: 0.5;
    visibility: hidden;
    filter: blur(8px);
    transform: scale(0.33);
    box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2);
}

@supports (offset-rotation: 0deg) {
    offset-rotation: 0deg;
    offset-path: path('M 250,100 S -300,500 -700,-200');
    .modal.off {
        offset-distance: 100%;
    }
}

@media (prefers-reduced-motion) {
    .modal {
        offset-path: none;
    }
}

.modal .h2 {
    border-bottom: 0px solid #ccc !important;
    padding: 1rem;
    margin: 0;
    color: #37465a;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0;
    font-weight: bold;
}

.modal .content {
    padding: 1rem;
    padding-left: 8%;
    padding-right: 8%;
}

.modal .actions {
    /*border-top: 1px solid #ccc;
  background: #eee;
  padding: 0.5rem 1rem;*/
    padding: 2rem 1rem;
    text-align: center;
}


/*.modal .actions button {
  border: 0;
  background: #78f89f;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  line-height: 1;
}*/

#centered-toggle-button {
    position: absolute;
}

select {
    background-image: url(siigo-flecha-abajo.svg);
    /*aquí deberás escribir la ruta de la imagen que utilizarás como flecha del desplegable*/
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 10px 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    text-align: left;
    padding-left: 0px !important;
    padding-right: 20px;
    /*border-bottom: none;*/
    color: #747474;
}

select::-ms-expand {
    display: none;
    /*Evita que se muestre la flecha por defecto en versiones de IE*/
}

.pLoader {
    text-align: center;
}

.closeModal {
    float: right;
    font-size: 30px;
    cursor: pointer;
}