@import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, 'Muli', BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f4f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  width: 100%;
}

p {
  font-family: 'Muli', sans-serif;
  font-size: 15px;
}

h2 {
  font-size: 24px;
}

h4 {
  font-size: 21px;
}

h5 {
  font-size: 15px;
}

h6 {
  font-size: 26px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.a-button {
  background: #00aaff;
  color: #ffffff;
  //text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  box-shadow: none;
  padding: 5px 0;
  border-radius: 25px;
  font-family: 'Muli', sans-serif;
  //width: 100%;
  border: 1px solid transparent;
  cursor: pointer;
  text-decoration: none;
  float: right;
  margin-top: -10px;

  &.green-fill {
    color: #ffffff;
    background: #69aa29;
    border-color: transparent;
    padding: 5px 20px;
    border-radius: 17px;

    &:hover {
      //background-color: #97bf19;
      //background: transparent;
      background: #518120;
      //border: 1px solid #97bf19;
      //color: #97bf19;
    }
  }

  &.green {
    //background-color: #97bf19;
    background: transparent;
    //background: #518120;
    border: 1px solid #ffa532;
    color: #ffa532;
    padding: 5px 20px;
    border-radius: 17px;

    &:hover {
      color: #ffffff;
      background: #ffa532;
      border-color: transparent;
    }
  }
  &.orange {
    //background-color: #97bf19;
    background: transparent;
    //background: #518120;
    border: 1px solid #7ac23a;
    color: #97bf19;
    padding: 5px 20px;
    border-radius: 17px;

    &:hover {
      color: #ffffff;
      background: #69aa29;
      border-color: transparent;
    }
  }

  &.blue {
    padding-left: 80px;
    padding-right: 80px;
    display: block;
    font-size: 16px;
  }

  &.orange {
    color: #ffa532;
    background: transparent;
    border: 1px solid #ffa532;
    padding: 5px 20px;
    border-radius: 14px;
    text-transform: none;
    font-size: 14px;
    font-weight: normal;
    width: initial;
    padding: 0.5em;

    &:hover {
      background-color: #ffa532;
      border-color: transparent;
      color: #ffffff;
    }
  }
}
.spanLogin {
  display: block;
  margin-top: -30px;
  margin-bottom: 30px;
}

.modal-span {
  color: red;
}

button {
  background: #00aaff;
  color: #ffffff;
  //text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  box-shadow: none;
  padding: 5px 0;
  border-radius: 25px;
  font-family: 'Muli', sans-serif;
  //width: 100%;
  padding: 5px 20px;
  border: 1px solid transparent;
  cursor: pointer;

  &.blue {
    background: #00aaff;
    color: #ffffff;
  }
  &:hover {
    background-color: #ffffff;
    border: 1px solid #00aaff;
    color: #00aaff;
  }
  &.blue-fill {
    background: #00aaff;
    color: #ffffff;
    &:hover {
      background: #0088cc;
    }
  }
  &.green {
    color: #7ac23a;
    background: transparent;
    border: 1px solid #7ac23a;
    padding: 5px 20px;
    border-radius: 14px;
    float: right;

    &:hover {
      background-color: #7ac23a;
      border-color: transparent;
      color: #ffffff;
    }
  }

  &.yellow {
    color: #ffc933;
    background: transparent;
    border: 1px solid #ffc933;
    padding: 5px 20px;
    border-radius: 14px;

    &:hover {
      background-color: #ffc933;
      border-color: transparent;
      color: #ffffff;
    }
  }
  &.orange {
    color: #ffa532;
    background: transparent;
    border: 1px solid #ffa532;
    padding: 5px 20px;
    border-radius: 14px;

    &:hover {
      background-color: #ffa532;
      border-color: transparent;
      color: #ffffff;
    }
  }

  &.red {
    color: #f7274e;
    background: transparent;
    border: 1px solid #f7274e;
    padding: 5px 20px;
    border-radius: 14px;

    &:hover {
      background-color: #f7274e;
      border-color: transparent;
      color: #ffffff;
    }
  }
  &.green-fill {
    color: #ffffff;
    background: #7ac23a;
    border-color: transparent;
    padding: 5px 20px;
    border-radius: 17px;

    &:hover {
      //background-color: #97bf19;
      background: #69aa29;
      //border: 1px solid #97bf19;
      //color: #97bf19;
    }

    &.disabled {
      color: #ffffff;
      background: #747474;
      border-color: transparent;
      padding: 5px 20px;
      border-radius: 17px;

      &:hover {
        //background-color: #97bf19;
        background: #5f5f5f;
        //border: 1px solid #97bf19;
        //color: #97bf19;
      }
    }
  }
  &.orange-fill {
    color: #fff;
    background-color: #ffa532;
    /*background: transparent;
    border: 1px solid #ffa532;*/
    padding: 5px 20px;
    border-radius: 14px;

    &:hover {
      background-color: #ff9000;
      border-color: transparent;
      color: #ffffff;
    }
  }

  &.gray-fill {
    color: #fff;
    background-color: #C1C3C7;
    /*background: transparent;
    border: 1px solid #ffa532;*/
    padding: 5px 20px;
    border-radius: 14px;

  }
  &.search {
    background-color: transparent;
    width: 20px;

    &:hover {
      background-color: transparent;
      border: 1px solid transparent;
    }
  }
  i {
    margin-right: 10px;
    font-style: normal;
    font-size: 25px;
    line-height: 0;
  }
}

.arrow {
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;

  &.azure {
    border-style: solid;
    border-color: #00aaff;
  }

  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  &.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}

/***** CONTAINERS DON'T TOUCH ******/

.icommerce {
  text-align: center;
}

.section {
  width: 100%;
}

section.container {
  display: flex;
  padding-left: 16%;
  padding-right: 3%;
  padding-top: 2%;
}

/**** CARD PROVISIONING ****/

.full-card {
  padding: 0 3% 0 5%;
  background: #ffffff;
  margin-bottom: 2%;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3% 0;
}

.card {
  text-align: center;

  &.is-4 {
    width: 25%;
  }
}

/**** CARD INPUTS ****/

.card-ddl {
  input {
    width: auto;
    padding: 5px;
    background: transparent;
    cursor: pointer;
    text-transform: capitalize;

    &:after {
      position: absolute;
      display: block;
      content: '';
    }
  }
}

.field {
  position: relative;
}

/**** ARROWS ****/

i.arrow {
  border: solid #00aaff;
  border-width: 0 2px 2px 0;
  padding: 3px;
  position: absolute;
  bottom: 10px;
  right: 5px;
  cursor: pointer;

  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

/**** REPORTS ****/

.report {
  .card.resume-report {
    .border {
      padding: 5%;
      border-width: 1px;
      border-style: solid;
      border-radius: 5px;
      margin-left: 10%;
    }
    h5 {
      margin-top: 0;
      margin-bottom: 5px;
    }
    h6 {
      margin: 0;
    }
  }
}

.input-container {
  margin-bottom: 30px;

  width: 100%;

  input, select {
    padding-left: 0px;
    padding-bottom: 0px;
    display: block;
    color: #747474;
  }
  label {
    display: none;
    font-size: 13px;
  }
}

.grid50 {
  display: flex;
}

.ml20 {
  margin-left: 20px;
}

/**** Grid ****/
.grid {
  display: grid;
  grid-template-columns: 80% 20%;
  //grid-template-rows: 200px 75px;
}
.grid2 {
  display: grid;
  grid-template-columns: 50% 50%;
  //grid-template-rows: 200px 75px;
}

.gridperfil {
  display: grid;
  grid-template-columns: 25% 75%;
  margin-top: 20px;
  margin-bottom: 20px;
  //grid-template-rows: 200px 75px;
}
.perfil {
  border: 1px solid #ccc;
  height: 300px;
  padding: 25px;

  label {
    font-size: 12px;
    color: #737373;
  }
  p {
    color: #737373;
    font-weight: bold;
  }
}
.imgperfil {
  background: #fff;
  text-align: center;
  img {
    //border-radius: 50%;
    width: 100%;
    height: 100%;
  }
}
.btnAvatar {
  display: none;
}
.divBtnAvatar {
  color: #00aaff;
  cursor: pointer;
  font-size: 20px;
  margin-top: 10px;
  text-decoration: underline;

  &:hover {
    color: #0088cc;
  }
}

.grid1perfil {
  display: grid;
}

.grid2perfil {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 15px;
  //grid-template-rows: 200px 75px;
}

.blue-h4 {
  color: #00aaff;
}

.gridMarket {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 15px;
  margin-top: 25px;
  background-color: #fff;
  border-radius: 10px;
}

.logsCenter {
  margin-top: 50px;
  font-size: 1.3em;
}

.gridLogSearch {
  display: grid;
  grid-template-columns: 20% 35% 20% 25%;
  padding: 15px;
  margin-top: 25px;
  background-color: #fff;
  border-radius: 10px;
}

.bg-same {
  background-color: #f1f4f9;
}

.gridSurveySearch {
  display: grid;
  grid-template-columns: 35% 30% 10%;
  padding: 15px;
  margin-top: 25px;
  background-color: #fff;
  border-radius: 10px;
}

.btnPerfil {
  position: absolute;
  right: 5%;
  margin-top: -50px;
}
.col {
  padding-right: 20px;
}
.col-p {
  padding: 10px;
}
.col-c {
  vertical-align: middle;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.p-top-20 {
  padding-top: 20px;
}

.center {
  text-align: center !important;
}

.titleMarket {
  font: Bold 39px/49px Muli;
  letter-spacing: 0;
  color: #37465a;
  opacity: 1;
  text-align: center;
  margin-top: 50px;
}

.subtitleMarket {
  font: Bold 17px/21px Muli;
  letter-spacing: 0;
  color: #747474;
  opacity: 1;
  text-align: center;
  margin-right: 6.5em;
}

.icon-cerrar {
  width: 25px;
  margin-bottom: -5px;
  margin-right: 10px;
}

.p-green {
  color: #41af4b !important;
  font-weight: bold;
  font-size: 18px;
}

.inputMarket {
  padding-left: 1px;
}
.inputTask {
  margin-top: 20px;
}

.btnbuscarm {
  margin-top: 30px;
  padding-left: 70px;
  padding-right: 70px;
}

.searchMarket {
  display: inherit;
  margin-right: 10px;
}

.input-container1 {
  width: 90%;
  margin-bottom: 0px;

  input {
    padding-left: 0px;
    padding-bottom: 0px;
    display: block;
    color: #747474;
  }
  label {
    display: inline-block;
    margin: 0.5em 0 0.5em 0;
    color: #7ac23a;
  }
  select {
    border-color: #737373;
    border-bottom: 1px solid;
    padding-bottom: 0px;
  }
  textarea {
    width: 90%;
    outline: 0 none;
  }
}

.resendEmail {
  label {
    margin-bottom: 40px;
  }
  a {
    color: #00aaff;
  }
}

.link {
  color: #00aaff;
  text-decoration: underline;
  cursor: pointer;
}

.input-container__modal1 {
  width: 90%;
  margin: 0em 0em 0.5em 3em;

  input {
    padding-left: 0px;
    padding-bottom: 0px;
    display: block;
    color: #747474;
  }
  label {
    display: inline-block;
    margin: 0.5em 0 0.5em 0;
    color: #7ac23a;
  }
  select {
    border-color: #737373;
    border-bottom: 1px solid;
    padding-bottom: 0px;
  }

  span {
    color: red;
  }

}

.save-contract {
  width: 190px;
  margin: -10px auto 0px; 

  button{
    width: 220px;
    height: 40px;
    margin:30px auto 0px; 
  }
}

.button-details {
  display: block;
}

.button-details_balance {
  display: inline-block;
  margin: 0em 1em 0em 0em;
}

.button-details_modal {
  color: blue;
	cursor: pointer;
	margin: 0 1em 1em 0;
	display: inline;
}

.icon-tooltip {
  display: inline;
  margin: 1em;
}

.input-container3 {
  width: 90%;
  margin-bottom: 0px;

  label {
    display: inline-block;
    margin: 0.5em 0 0.5em 0;
    color: #7ac23a;
  }
}

.input-container2 {
  padding-left: 10%;
}

.tooltip{
  display: inline;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 17em;
  background-color: #FFFFFF;
  filter: drop-shadow(0px 1px 1px #444); 
  color: #000000;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: -250%;
  left: 100%;
  margin-left: -40px;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 0.4em 1em 0.4em 1em;
  line-height: 2em;
  font-size: 11px;
}

.tooltiptext{
  margin: 1em 1em 1em 1em;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -50%;
  left: 2.5%;
  margin-top: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent ;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.hide {
  display: none;
}

.imgRedonda {
  width: 100%;
  height: 90%;
  border-radius: 50%;
  overflow: hidden;
}

.row {
  display: flex;
}

.column4 {
  flex: 20%;
  padding: 5px;
}
.column3 {
  flex: 33%;
  padding: 5px;
}
.column2 {
  flex: 50%;
  padding-left: 5px;
}
.refundBtn {
  padding-left: 5px;
}

.divFilter {
  padding: 15px;
  margin-top: 25px;
  background-color: #fff;
  border-radius: 10px;
}


/* GENERAL MARGIN */
.ml-1 {
  margin-left: (16px * .25) !important;
}
.ml-2 {
  margin-left: (16px * .5) !important;
}
.ml-3 {
  margin-left: (16px) !important;
}
.ml-4 {
  margin-left: (16px * 1.5) !important;
}
.mr-1 {
  margin-right: (16px * .25) !important;
}
.mr-2 {
  margin-right: (16px * .5) !important;
}
.mr-3 {
  margin-right: (16px) !important;
}
.mr-4 {
  margin-right: (16px * 1.5) !important;
}
.mt-1 {
  margin-top: (16px * .25) !important;
}
.mt-2 {
  margin-top: (16px * .5) !important;
}
.mt-3 {
  margin-top: (16px) !important;
}
.mt-4 {
  margin-top: (16px * 1.5) !important;
}
.mb-1 {
  margin-bottom: (16px * .25) !important;
}
.mb-2 {
  margin-bottom: (16px * .5) !important;
}
.mb-3 {
  margin-bottom: (16px) !important;
}
.mb-4 {
  margin-bottom: (16px * 1.5) !important;
}

/* GENERAL TEXT */

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-rigth {
  text-align: right;
}

.pointer {
  cursor: pointer;
}